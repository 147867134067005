export const CONTROLLER_URL = "https://gsrh-api.espedata.fr/"; 
export const API_URL = "https://gsrh-api.espedata.fr/api/";

/* DROP CONTROLLER */
export const UPLOADFILE = CONTROLLER_URL + "upload";
export const DISPLAYFILE = CONTROLLER_URL + "displayFile";
export const FILEDELETE = CONTROLLER_URL + "filedelete";
export const DELETEALLFILES = CONTROLLER_URL + "deleteAllFiles";
export const EXTRACTIONFILE = CONTROLLER_URL + "extraction";
export const EXTRACTIONFILEDSN = CONTROLLER_URL + "extractionDsn";
export const SORTEDNOMENCLATUREGRADE = CONTROLLER_URL + "sortedNomenclatureGrade";
export const REASSOCIATIONCODESPAIES = CONTROLLER_URL + "reassociationCodePaie";

/* OTHERBASE CONTROLLER */
export const DBTOEXCEL = CONTROLLER_URL + "dbtoexcel";
export const UPLOADEXCEL = CONTROLLER_URL + "excelupload";
export const EXCELTODB = CONTROLLER_URL + "exceltodb";
export const DELETERETURNEXCEL = CONTROLLER_URL + "deleteReturnXls";
export const DELETEEXCELFILE = CONTROLLER_URL + "deleteExcelFile";
export const EXCELDISPLAYFILE = CONTROLLER_URL + "exceldisplayFile";
export const RELOOP_ASSOCIATION = CONTROLLER_URL + "reassociation";

/* EMAIL CONTROLLER */ 
export const SENDEMAIL = CONTROLLER_URL + "forgotten_password";

/* USER CONTROLLER */
export const CREATEDIRECTORY = CONTROLLER_URL + "createUser";
export const DELETEDIRECTORY = CONTROLLER_URL + "deleteUser";
export const FINDUSERBYEMAIL = CONTROLLER_URL + "findUserByEmail";

/* APIPLATFORM */
export const GRADECORRESP_API = API_URL + "grade_correspondances";
export const GRADENOMENC_API = API_URL + "nomenclature_grades";

export const CODEPAIECORRESP_API = API_URL + "code_paie_correspondances";
export const CODEPAIENOMENC_API = API_URL + "nomenclature_code_paies";

export const SERVICECORRESPONDANCE_API = API_URL + "service_correspondances";
export const SERVICENOMENC_API = API_URL + "nomenclature_services";
export const SERVICENOMENCPOLITIQUEPUBLIQUE_API = API_URL + "nomenclature_politique_publiques";

export const ECHELONCORRESPONDANCE_API = API_URL + "echelon_correspondances";
export const ECHELONNOMENC_API = API_URL + "nomenclature_echela";

export const METIERCORRESPONDANCE_API = API_URL + "metier_correspondances";
export const METIERNOMENC_API = API_URL + "nomenclature_metiers";

export const LOGINCHECK_API = API_URL + "login_check";
export const CLIENTS_API = API_URL + "clients";
export const USERS_API = API_URL + 'users';
export const USERS_LOG_API = API_URL + 'user_logs';
export const EMPLOYEURS_API = API_URL + "employeurs";
export const AGENTS_API = API_URL + "agents";
export const AGENTSMOIS_API = API_URL + "agent_moiss";

export const DISPLAYEFFECTIFXEMELIOS = CONTROLLER_URL + "effectifXemelios";
export const DELETEEFFECTIFXEMELIOS = CONTROLLER_URL + "deleteEffectifXemelios";
export const DISPLAYEFFECTIFDSN = CONTROLLER_URL + "effectifDsn";
export const DELETEEFFECTIFDSN = CONTROLLER_URL + "deleteEffectifDsn";

export const RETRAITEMENTBASEABSENCE = CONTROLLER_URL + "retraitementBaseAbsence";
export const DELETECODEPAIEDOUBLON = CONTROLLER_URL + "deleteCodePaieDoublon";

export const EXPORT_GRADE_XLS = CONTROLLER_URL + "api/exportXlsGrade";
export const EXPORT_SERVICE_XLS = CONTROLLER_URL + "api/exportXlsService";
export const EXPORT_METIER_XLS = CONTROLLER_URL + "api/exportXlsMetier";
export const EXPORT_CODE_PAIE_XLS = CONTROLLER_URL + "api/exportXlsCodePaie";

export const GET_DETAIL_PAR_MOIS = CONTROLLER_URL + "api/getDetailParMois";
export const EXPORT_DETAIL_PAR_MOIS = CONTROLLER_URL + "api/exportXlsDetailParMois";
export const GET_FICHE_DE_PAIE = CONTROLLER_URL + "api/getFicheDePaie";
export const EXPORT_FICHE_DE_PAIE = CONTROLLER_URL + "api/exportXlsFicheDePaie";
export const GET_MATRICULES = CONTROLLER_URL + "api/getMatricules";
export const GET_YEARS_BY_MATRICULE = CONTROLLER_URL + "api/getYearsByMatricule";
export const GET_MONTH_BY_YEAR_AND_MATRICULE = CONTROLLER_URL + "api/getMoisByYearAndMatricule";
export const EXPORT_BASE_AGENTS_XEMELIOS = CONTROLLER_URL + "api/exportXlsBaseAgentsXemelios";
export const GET_YEAR_DSN = CONTROLLER_URL + "api/getYearsDsn";
export const GET_MONTH_BY_YEAR_DSN = CONTROLLER_URL + "api/getMoisByYearDsn";
export const EXPORT_BASE_AGENTS_DSN = CONTROLLER_URL + "api/exportXlsBaseAgentsDsn";