import React, {useContext, useState, useEffect} from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { styled } from "@mui/material/styles";
import jwtDecode from "jwt-decode";
import useMediaQuery from '@mui/material/useMediaQuery';
import Swal from 'sweetalert2';
import ls from 'localstorage-slim';

import AuthAPI from "../../services/AuthAPI";
import AuthContext from "../../contexts/AuthContext"
import Gsrh from "../../images/logogsrhompact.png";
import Logo from "../../images/logogsrh.png";

import { Drawer,
         CssBaseline,
         Toolbar,
         ListItemText,
         ListItemIcon,
         AppBar,
         List,
         Typography,
         Divider,
         ListItemButton,
         Button,
         IconButton,
		 Popover,
		 TextField,
		 Grid,
		 Collapse,
		 Autocomplete
       } from '@mui/material';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import BarChartIcon from '@mui/icons-material/BarChart';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import ForumIcon from '@mui/icons-material/Forum';
import PersonIcon from '@mui/icons-material/Person';
import TuneIcon from '@mui/icons-material/Tune';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		'& .MuiAppBar-colorPrimary': {
			backgroundColor: "#3492aa",
			zIndex: theme.zIndex.drawer + 1,
		},
		'& .MuiListItem-root:hover': {
			backgroundColor: "#eb6c29"
		}
	},
	listitem: {
		'&:hover': {
			backgroundColor: "#eb6c29",
		},
		// [theme.breakpoints.up('xl')]: {
		// 	maxHeight: 595,
		// },
	},
	drawer: {
		width: 200,
		flexShrink: 0,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	title: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	toolbar: theme.mixins.toolbar,
	divlogogsrh: {
		width: 100,
		marginTop: 10,  
	},
	logogsrh: {
		width: '100%',
		height: '100%',
		margin: 0,
		padding: 0,
	},
	sidebarlink: {
		textDecoration: 'none',
	},
	sidebar: {
		margin: 0,
		width: 205,
		backgroundColor: '#4b4644',
		height: '100%',
		[theme.breakpoints.down(1280)]: {
			marginTop: 60,
		}
	},
	icon: {
		color: 'white',
	},
	icontext: {
		fontFamily: 'AvantGardeBold',
		color: 'white',
		fontSize: 12
	},
	logocontainer: {
		width: 70,
		height: 80,
		left: "28%",
		position: 'absolute',
		bottom: 10
	},
	logo: {
		width: '100%',
		height: '100%',
		margin: 0,
		padding: 0,
	},
	"& .MuiTypographyRoot": {
		fontSize: '1.5em'
	},
	popover: {
		width: 300,
		padding: 10
	},
	divider: {
		marginTop: 10,
		marginBottom: 10
	},
	iconUser: {
		marginTop: 5,
		marginBottom: 10
	},
}));

const CutsomizeListItem = styled(ListItemButton)(({theme}) => ({
	paddingLeft: theme.spacing(4),
	'&.Mui-selected':{
		backgroundColor: "rgba(235, 108, 41, 0.5)",

	},
	'&.Mui-selected:hover':{
		backgroundColor: "rgba(235, 108, 41, 0.5)",

	},
	"&:hover": {
		backgroundColor: "rgba(235, 108, 41, 0.5)",
		color: "white",
		"& .MuiListItemIcon-root": {
			color: "white"
		}
	},
	[theme.breakpoints.down(1900)]: {
		paddingTop: 2,
		paddingBottom: 2,
	},
}));

export default function Navbar({history, passingClient, setPassingClient, clientsArr, reload}) {
	const classes = useStyles();
	const responsiveDevice = useMediaQuery('(min-width:1280px)');
	const location = useLocation();

	const UrlPBI = "//app.powerbi.com";
	const UrlJalios = "//sp2000.jaliosagora.com/jcms/c_1371796/fr/gs-rh-communaute";

	const [state, setState] = useState({left: false});
	const [token, setToken] = useState();
	const [anchorEl, setAnchorEl] = useState(null);
	const [openSubList, setOpenSubList] = useState({
		administrer: false,
		deposer: false,
		parametrer: false,
		analyser: false,
		echanger: false
	});
	const [selectedIndex, setSelectedIndex] = React.useState(1);
	const [clientStorage, setClientStorage] = useState(null);
	const [clients, setClients] = useState([]);

	useEffect(() => {
		if (Array.isArray(clientsArr)) {
			setClients(clientsArr);
		}	
	}, [clientsArr]);

	// Fonction permettant d'ouvrir le burgermenu
	const toggleDrawer = (side, open) => event => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		setState({ ...state, [side]: open });
	};

	const {isAuthenticated, setIsAuthenticated} = useContext(AuthContext);

	const handleLogout = () => {
		setPassingClient(null);
		setIsAuthenticated(false);
		AuthAPI.logout();
		reload();
	}
	
	/* Permet de récupérer le token et de connaitre le role de l'utilisateur */
	const getDecodedToken = () => {
		const tokenBrut = window.localStorage.getItem("authToken");
		
		if(tokenBrut){
			const decodedToken = jwtDecode(tokenBrut);
			setToken(decodedToken);
		}
	}

	useEffect(() => {
		getDecodedToken();
	}, []);

	useEffect(() => {
		setClientStorage(passingClient);
	}, [passingClient]);

	const logOutExpiredToken = () => {
		if(AuthAPI.isAuthenticated() === false){
		  	Swal.fire({
				icon: 'error',
				title: 'La session a expiré.',
				text: 'Veuillez-vous reconnecter.',
			});
		  	handleLogout();
		}
	}

	useEffect(() => {
		logOutExpiredToken();
	});

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const handleChangeClient = (event, option) => {
		event.preventDefault();

		if(option === null){
			ls.remove("client");
		} else {
			ls.set("client", JSON.stringify(option), { encrypt: true });            
		}
		setPassingClient(option);
    }

	useEffect(() => {
		// Restaurer l'état depuis localStorage au montage
		const savedSubListState = ls.get("openSubList");
		const savedSelectedIndex = ls.get("selectedIndex");
	
		if (savedSubListState) {
			setOpenSubList(savedSubListState);
		}
	
		if (typeof savedSelectedIndex === "number") {
			setSelectedIndex(savedSelectedIndex);
		}
	}, []);

	const handleOpenSubList = (param) => {
		setOpenSubList({
			administrer: false,
				deposer: false,
				parametrer: false,
				analyser: false,
				echanger: false, 
				[param]: !openSubList[param],
		})

	};

	const handleListItemClick = (event, index) => {
		setSelectedIndex(index);
	
		// Sauvegarder l'état des sous-listes et de l'index sélectionné
		ls.set("selectedIndex", index);

		// Sauvegarder l'état des sous-listes au moment de la sélection
		ls.set("openSubList", openSubList);
	};

	useEffect(() => {
		if(location.pathname === "/controle"){
			setOpenSubList({...openSubList, deposer: true});	
			setSelectedIndex(5);
		}
	}, [location.pathname]);

  	// Onglets de la sidebar
	const sideList = side => (
		<div className={classes.sidebar} onKeyDown={toggleDrawer(side, false)}>
			<List>
				<NavLink to="/accueil" className={classes.sidebarlink}>
					<ListItemButton
						className={classes.listitem} 
						selected={selectedIndex === 0}
						onClick={(event) => handleListItemClick(event, 0)}
					>
						<ListItemIcon><HomeIcon className={classes.icon}/></ListItemIcon>
						<ListItemText className={classes.icontext}>Accueil</ListItemText>
					</ListItemButton>
				</NavLink>
				<Divider />
				{token ? 
					token.roles.map((token, i) => (
						token === "ROLE_ADMIN" || token === "ROLE_SUPER_ADMIN" ?
							<React.Fragment key={"lien"+i}>
									<ListItemButton className={classes.listitem} onClick={() => handleOpenSubList("administrer")}>
										<ListItemIcon>
											<StarBorder className={classes.icon} />
										</ListItemIcon>
										<ListItemText primary="Administrer" className={classes.icontext} />
										{openSubList.administrer ? <ExpandMore className={classes.icon} /> : <ExpandLess className={classes.icon} />}
									</ListItemButton>
									<Collapse in={openSubList.administrer} timeout="auto" unmountOnExit>
										<List component="div" disablePadding>
											<NavLink to="/compte" className={classes.sidebarlink}>
												<CutsomizeListItem 
													selected={selectedIndex === 1}
													onClick={(event) => handleListItemClick(event, 1)}
												>
													<ListItemText primary="Compte" className={classes.icontext} />
												</CutsomizeListItem>
											</NavLink>

										</List>
									</Collapse>
								<Divider />
							</React.Fragment>
						: null
					)) 
				: null
				}
				<Divider />
				<ListItemButton className={classes.listitem} onClick={() => handleOpenSubList("deposer")}>
					<ListItemIcon>
						<MoveToInboxIcon className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Déposer" className={classes.icontext} />
					{openSubList.deposer ? <ExpandMore className={classes.icon} /> : <ExpandLess className={classes.icon} />}
				</ListItemButton>
				<Collapse in={openSubList.deposer} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<NavLink to="/depotXemelios" className={classes.sidebarlink} key={"navlink1"}>
							<CutsomizeListItem 
								className={classes.nested} 
								selected={selectedIndex === 2}
								onClick={(event) => handleListItemClick(event, 2)}
							>
								<ListItemText primary="Dépôt des xemelios" className={classes.icontext} />
							</CutsomizeListItem>
						</NavLink>
						<NavLink to="/depotDns" className={classes.sidebarlink} key={"navlink2"}>
							<CutsomizeListItem
								className={classes.nested} 
								selected={selectedIndex === 3}
								onClick={(event) => handleListItemClick(event, 3)}
							>
								<ListItemText primary="Dépôt des DSN" className={classes.icontext} />
							</CutsomizeListItem>
						</NavLink>
						<NavLink to="/effectif" className={classes.sidebarlink} key={"navlink3"}>
							<CutsomizeListItem 
								className={classes.nested} 
								selected={selectedIndex === 4}
								onClick={(event) => handleListItemClick(event, 4)}
							>
								<ListItemText primary="Vérification des effectifs" className={classes.icontext} />
							</CutsomizeListItem>
						</NavLink>
						{token ? 
							token.roles.map((token, i) => (
								token === "ROLE_SUPER_ADMIN" ?
									<NavLink to="/controle" className={classes.sidebarlink} key={"navlink4"}>
										<CutsomizeListItem
											className={classes.nested} 
											selected={selectedIndex === 5}
											onClick={(event) => handleListItemClick(event, 5)}
										>
											<ListItemText primary="Contrôle des données" className={classes.icontext} />
										</CutsomizeListItem>
									</NavLink>
										: null
								)) 
							: null
						}
						<NavLink to="/exports" className={classes.sidebarlink} key={"navlink5"}>
							<CutsomizeListItem
								className={classes.nested} 
								selected={selectedIndex === 6}
								onClick={(event) => handleListItemClick(event, 6)}
							>
								<ListItemText primary="Exports" className={classes.icontext} />
							</CutsomizeListItem>
						</NavLink>
					</List>
				</Collapse>
				<Divider />
				<ListItemButton className={classes.listitem} onClick={() => handleOpenSubList("parametrer")}>
					<ListItemIcon>
						<TuneIcon className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Paramétrer" className={classes.icontext} />
					{openSubList.parametrer ? <ExpandMore className={classes.icon} /> : <ExpandLess className={classes.icon} />}
				</ListItemButton>
				<Collapse in={openSubList.parametrer} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<NavLink to="/grades" className={classes.sidebarlink}>
							<CutsomizeListItem
								className={classes.nested} 
								selected={selectedIndex === 7}
								onClick={(event) => handleListItemClick(event, 7)}
							>
								<ListItemText primary="Grades" className={classes.icontext} />
							</CutsomizeListItem>
						</NavLink>
						<NavLink to="/codespaie" className={classes.sidebarlink}>
							<CutsomizeListItem
								className={classes.nested} 
								selected={selectedIndex === 8}
								onClick={(event) => handleListItemClick(event, 8)}
							>
								<ListItemText primary="Codes paie" className={classes.icontext} />
							</CutsomizeListItem>
						</NavLink>
						<NavLink to="/services" className={classes.sidebarlink}>
							<CutsomizeListItem 
								className={classes.nested} 
								selected={selectedIndex === 9}
								onClick={(event) => handleListItemClick(event, 9)}
							>
								<ListItemText primary="Services" className={classes.icontext} />
							</CutsomizeListItem>
						</NavLink>
						<NavLink to="/metiers" className={classes.sidebarlink}>
							<CutsomizeListItem
								className={classes.nested} 
								selected={selectedIndex === 10}
								onClick={(event) => handleListItemClick(event, 10)}
							>
								<ListItemText primary="Métiers" className={classes.icontext} />
							</CutsomizeListItem>
						</NavLink>
						<NavLink to="/echelons" className={classes.sidebarlink}>
							<CutsomizeListItem
								className={classes.nested} 
								selected={selectedIndex === 11}
								onClick={(event) => handleListItemClick(event, 11)}
							>
								<ListItemText primary="Échelons" className={classes.icontext} />
							</CutsomizeListItem>
						</NavLink>
						<NavLink to="/ficheAgent" className={classes.sidebarlink}>
							<CutsomizeListItem 
								className={classes.nested} 
								selected={selectedIndex === 12}
								onClick={(event) => handleListItemClick(event, 12)}
							>
								<ListItemText primary="Fiche agent" className={classes.icontext} />
							</CutsomizeListItem>
						</NavLink>
						<NavLink to="/autresbases" className={classes.sidebarlink}>
							<CutsomizeListItem 
								className={classes.nested} 
								selected={selectedIndex === 13}
								onClick={(event) => handleListItemClick(event, 13)}
							>
								<ListItemText primary="Autres bases" className={classes.icontext} />
							</CutsomizeListItem>
						</NavLink>
					</List>
				</Collapse>
				<Divider />
				<ListItemButton className={classes.listitem} onClick={() => handleOpenSubList("analyser")}>
					<ListItemIcon>
						<BarChartIcon className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Analyser" className={classes.icontext} />
					{openSubList.analyser ? <ExpandMore className={classes.icon} /> : <ExpandLess className={classes.icon} />}
				</ListItemButton>
				<Collapse in={openSubList.analyser} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<a href={UrlPBI} target="_blank" rel="noopener noreferrer" className={classes.sidebarlink}>
							<CutsomizeListItem 
								className={classes.nested} 
								selected={selectedIndex === 14}
								onClick={(event) => handleListItemClick(event, 14)}
							>
								<ListItemText className={classes.icontext}>PBI</ListItemText>
							</CutsomizeListItem>
						</a>
					</List>
				</Collapse>
				<Divider />
				<ListItemButton className={classes.listitem} onClick={() => handleOpenSubList("echanger")}>
					<ListItemIcon>
						<ForumIcon className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Échanger" className={classes.icontext} />
					{openSubList.echanger ? <ExpandMore className={classes.icon} /> : <ExpandLess className={classes.icon} />}
				</ListItemButton>
				<Collapse in={openSubList.echanger} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<a href={UrlJalios} target="_blank" rel="noopener noreferrer" className={classes.sidebarlink}>
							<CutsomizeListItem 
								className={classes.nested} 
								selected={selectedIndex === 15}
								onClick={(event) => handleListItemClick(event, 15)}
							>
								<ListItemText className={classes.icontext}>Support & Communauté</ListItemText>
							</CutsomizeListItem>
						</a>
					</List>
				</Collapse>
			</List>
			<div className={classes.logocontainer}>
				<img src={Logo} alt="Logo GS'RH" className={classes.logo} />
			</div>
		</div>
	);

    if(responsiveDevice){
		return (
			// Si plus grand qu'un smartphone
			<div className={classes.root}>
				<CssBaseline />
				<AppBar position="fixed">
					<Toolbar>
						<Typography variant="h6" className={classes.title}>
							<div className={classes.divlogogsrh}>
								<img className={classes.logogsrh} src={Gsrh} alt="Logo GS'RH" />
							</div>
						</Typography>
						{!isAuthenticated ? 
							<Link to="/seconnecter" className={classes.sidebarlink}>
								<Button color="white">Login</Button>
							</Link>
						: 
							<IconButton onClick={handleClick} aria-label="compte" aria-describedby={id}>
								<AccountBoxIcon fontSize="large" style={{color: "white"}} />
							</IconButton>
						}
					</Toolbar>
				</AppBar>
				<Drawer 
					className={classes.drawer}
					variant="permanent"
				> 
					<div className={classes.toolbar} />
					{sideList('left')}
				</Drawer>
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<div className={classes.popover}>
						{token &&
						<>
							<Grid container className={classes.iconUser}>
								<PersonIcon style={{marginRight: 5}} />
								<Typography>
									Bonjour, {token.prenom} {token.nom}
								</Typography>
							</Grid>
							<Divider className={classes.divider} />
						</>
						}
						<Autocomplete
							filterSelectedOptions
							options={clients}
							getOptionLabel={(option) => option.nomClient}
							value={clientStorage}
							isOptionEqualToValue={(option, value) => option.idclient === value.idclient}
							renderInput={(params) =>
								<TextField {...params} label="Choix du client" />
							}
							onChange={(e, option) => handleChangeClient(e, option)}
							disabled={clients.length > 1 ? false : true}
						/>
						<Divider className={classes.divider} />
						<Button onClick={handleLogout} color="inherit">Déconnexion</Button>
					</div>

				</Popover>
			</div>
		)
    } else {
		return (
			// Si taille smartphone
			<div className={classes.root}>
				<CssBaseline />
				<AppBar position="fixed" className={classes.appBar}>
					<Toolbar>
						<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawer('left', true)}>
							<MenuIcon />
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							<div className={classes.divlogogsrh}>
								<img className={classes.logogsrh} src={Gsrh} alt="Logo GS'RH" />
							</div>
						</Typography>
						{!isAuthenticated ? 
							<Link to="/seconnecter" className={classes.sidebarlink}>
								<Button color="white">Login</Button>
							</Link>
						: 
							<IconButton onClick={handleClick} aria-label="compte" aria-describedby={id}>
								<AccountBoxIcon fontSize="large" style={{color: "white"}} />
							</IconButton>
						}
					</Toolbar>
				</AppBar>
				<Drawer open={state.left} onClose={toggleDrawer('left', false)}>
					{sideList('left')}
				</Drawer>
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<div className={classes.popover}>
						{token &&
						<>
							<Grid container className={classes.iconUser}>
								<PersonIcon style={{marginRight: 5}} />
								<Typography>
									Bonjour, {token.prenom} {token.nom}
								</Typography>
							</Grid>
							<Divider className={classes.divider} />
						</>
						}
						<Autocomplete
							filterSelectedOptions
							options={clients}
							value={clientStorage}
							getOptionLabel={(option) => option.nomClient}
							isOptionEqualToValue={(option, value) => option.idclient === value.idclient}
							renderInput={(params) =>
								<TextField {...params} 	label="Choix du client" />
							}
							onChange={(e, option) => handleChangeClient(e, option)}
							disabled={clients.length > 1 ? false : true}
						/>
						<Divider className={classes.divider} />
						<Button onClick={handleLogout} color="inherit">Déconnexion</Button>
					</div>

				</Popover>
			</div>
		)
    }
}

