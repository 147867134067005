import React, { useState, useEffect, Suspense } from 'react';
import { HashRouter, Route, Switch, withRouter, Redirect, useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import ls from 'localstorage-slim';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AuthAPI from './services/AuthAPI';
import AuthContext from './contexts/AuthContext';
import PrivateRoute from './components/privateRoute';
import Navbar from './components/navbar/navbar';
import ScrollIntoView from "./components/scrollIntoView";
import Box from '@mui/material/Box';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

// Lazy load components
const Home = React.lazy(() => import('./templates/home'));
const DropFileXemelios = React.lazy(() => import('./templates/dropFileXemelios'));
const DropFileDns = React.lazy(() => import('./templates/dropFileDNS'));
const AddCount = React.lazy(() => import('./templates/addCount'));
const OtherBase = React.lazy(() => import('./templates/otherBase'));
const LoginPage = React.lazy(() => import('./templates/loginPage'));
const ForgetPassword = React.lazy(() => import('./templates/forgetPassword'));
const Effectif = React.lazy(() => import('./templates/effectif'));
const FicheAgent = React.lazy(() => import('./templates/ficheAgent'));
const Exports = React.lazy(() => import('./templates/exports'));
const Controle = React.lazy(() => import('./templates/userLog'));
const Grade = React.lazy(() => import('./components/onglets/grade'));
const CodePaie = React.lazy(() => import('./components/onglets/codePaie'));
const Service = React.lazy(() => import('./components/onglets/service'));
const Metier = React.lazy(() => import('./components/onglets/metier'));
const Echelon = React.lazy(() => import('./components/onglets/echelon'));

AuthAPI.setup();

const theme = createTheme();

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(AuthAPI.isAuthenticated());
  const [access, setAccess] = useState();
  const history = useHistory();

  const contextValue = { isAuthenticated, setIsAuthenticated };

  const [passingClient, setPassingClient] = useState(null);
  const [clients, setClients] = useState(ls.get('clients', { decrypt: true }) || []);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setAccess(AuthAPI.getDecodedToken());

    const fetchClientsOnAuth = async () => {
      await AuthAPI.getUserClients();
      const fetchDecodedClient = AuthAPI.getDecodedClient();

      if (fetchDecodedClient[0] !== null) {
        setClients(fetchDecodedClient[0]);
      }

      if (fetchDecodedClient[1] !== null) {
        setPassingClient(fetchDecodedClient[1]);
      }
    };

    if (isAuthenticated) {
      fetchClientsOnAuth();
    }
  }, [isAuthenticated]);

  const reloadComponent = () => {
    setReload(!reload);
  };

  const NavbarWithRouter = withRouter(Navbar);

  return (
    <ThemeProvider theme={theme}>
      <AuthContext.Provider value={contextValue}>
        <HashRouter>
          {isAuthenticated && (
            <NavbarWithRouter
              setPassingClient={setPassingClient}
              passingClient={passingClient}
              clientsArr={clients}
              reload={reloadComponent}
              history={history}
            />
          )}
          <Box id="mainBox">
            <ScrollIntoView>
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  <Route exact path="/seconnecter" component={LoginPage} />
                  <Route exact path="/motdepasseoublie" component={ForgetPassword} />
                  <PrivateRoute exact path="/accueil" component={Home} />
                  <PrivateRoute exact path="/depotXemelios" component={() => <DropFileXemelios passingClient={passingClient} />} />
                  <PrivateRoute exact path="/depotDns" component={() => <DropFileDns passingClient={passingClient} />} />
                  <PrivateRoute exact path="/grades" component={() => <Grade passClient={passingClient} />} />
                  <PrivateRoute exact path="/codespaie" component={() => <CodePaie passClient={passingClient} />} />
                  <PrivateRoute exact path="/services" component={() => <Service passClient={passingClient} />} />
                  <PrivateRoute exact path="/metiers" component={() => <Metier passClient={passingClient} />} />
                  <PrivateRoute exact path="/echelons" component={() => <Echelon passClient={passingClient} />} />
                  <PrivateRoute exact path="/effectif" component={() => <Effectif passingClient={passingClient} />} />
                  <PrivateRoute exact path="/ficheAgent" component={() => <FicheAgent passingClient={passingClient} />} />
                  <PrivateRoute exact path="/autresbases" component={() => <OtherBase passingClient={passingClient} />} />
                  <PrivateRoute exact path="/exports" component={() => <Exports passingClient={passingClient} />} />
                  {access ? (
                    <>
                      <PrivateRoute exact path="/compte" component={() => <AddCount reload={reloadComponent} />} />
                      <PrivateRoute exact path="/controle" component={() => <Controle passingClient={passingClient} reload={reloadComponent} />} />
                    </>
                  ) : (
                    <Redirect to="/accueil" />
                  )}
                  <Redirect to="/accueil" />
                </Switch>
              </Suspense>
            </ScrollIntoView>
          </Box>
          <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} newestOnTop />
        </HashRouter>
      </AuthContext.Provider>
    </ThemeProvider>
  );
};

export default App;