import axios from "axios";
import jwtDecode from "jwt-decode";
import {LOGINCHECK_API, USERS_API} from "../config.js";
import ls from 'localstorage-slim';

function authenticate(credentials) {
    return axios
        .post(LOGINCHECK_API, credentials)
        .then(response => response.data.token)
        .then(token => {
            // window.localStorage.setItem("authToken", token);
            ls.set("authToken", token);
            // console.log("Connectée !");
            setAxiosToken(token);
        })
}

function logout() {
    ls.remove("authToken");
    ls.remove("client");
    ls.remove("clients");
    ls.remove("openSubList");
    ls.remove("selectedIndex");
    delete axios.defaults.headers["Authorization"];
    window.location.href = '#/seconnecter'
}

function setAxiosToken(token) {
    axios.defaults.headers["Authorization"] = "Bearer " + token;
}

function setup() {
    const token = ls.get('authToken', { decrypt: true });

    if(token) {
        const jwtData = jwtDecode(token);
        if(jwtData.exp * 1000 > new Date().getTime()){
            setAxiosToken(token);
        }
    }
}

function isAuthenticated() {
    const token = ls.get('authToken', { decrypt: true });

    if(token) {
        const jwtData = jwtDecode(token);
        if(jwtData.exp * 1000 > new Date().getTime()){
            return true;
        }
        return false;
    }
    return false;
}

function getDecodedToken() {
    const tokenBrut = ls.get('authToken', { decrypt: true });

    if(tokenBrut){
        const decodedToken = jwtDecode(tokenBrut);
        const accessRole = decodedToken.roles;

        for(let i = 0; i < accessRole.length; i++){
            if(accessRole[i] === "ROLE_ADMIN" || accessRole[i] === "ROLE_SUPER_ADMIN"){
                return true;
            } else {
                return false;
            }
        }
    }
}

function getUserClients() {
    let token = ls.get('authToken', { decrypt: true });
    const decodedToken = jwtDecode(token);

    if(token){
        return axios
        .get(USERS_API + "/" + decodedToken.id)
        .then(response => {
            let user = response.data;
            let clients = user.clients;

            if(clients.length <= 1){
                ls.set("client", JSON.stringify(clients[0]), { encrypt: true });            
            } else {
                ls.set("clients", JSON.stringify(clients), { encrypt: true });            

            }
        });
    }
}

function getDecodedClient() {
    const clients = ls.get('clients', { decrypt: true })
    const decodedClients = JSON.parse(clients);

    if(clients){
    	decodedClients.sort(function(a, b) {
    		var textA = a.nomClient.toUpperCase();
    		var textB = b.nomClient.toUpperCase();
    		return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    	});
    }

    const client = ls.get('client', { decrypt: true })
    const decodedClient = JSON.parse(client);

    return [decodedClients, decodedClient];
}

const logger = {
    authenticate,
    logout,
    setup,
    isAuthenticated,
    getDecodedToken,
    getUserClients,
    getDecodedClient
}

export default logger;